var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dados-corretora" }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "h4",
        { staticClass: "vx-col p-0 w-full flex items-center font-semibold" },
        [
          _c("span", { staticClass: "block" }, [_vm._v("Dados da Corretora")]),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.text.textCorretora, position: "right" } },
            [_c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")])]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mt-5 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: "Razão Social",
              size: "large",
              value: _vm.cadastro.Name,
              disabled: ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pl-0 md:pr-2 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: "CNPJ",
              size: "large",
              value: _vm._f("maskCnpj")(_vm.cadastro.CpfCnpj),
              disabled: "true"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              label: "Nroº Susep",
              size: "large",
              value: _vm.cadastro.SUSEPProtocol,
              disabled: ""
            }
          })
        ],
        1
      ),
      !_vm.Sombrero
        ? _c(
            "div",
            { staticClass: "vx-col w-full mt-5 p-0" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "label-placeholder": "Inscrição Municipal",
                  type: "text",
                  size: "large"
                },
                model: {
                  value: _vm.cadastro.InscricaoMunicipal,
                  callback: function($$v) {
                    _vm.$set(_vm.cadastro, "InscricaoMunicipal", $$v)
                  },
                  expression: "cadastro.InscricaoMunicipal"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "vx-col w-full mt-5 p-0" },
            [
              _c("ValidationProvider", {
                attrs: { name: "Inscrição Municipal", rules: "required" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "label-placeholder": "Inscrição Municipal",
                            size: "large"
                          },
                          model: {
                            value: (_vm.cadastro || {}).InscricaoMunicipal,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.cadastro || {},
                                "InscricaoMunicipal",
                                $$v
                              )
                            },
                            expression: "(cadastro || {}).InscricaoMunicipal"
                          }
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: errors[0],
                                expression: "errors[0]"
                              }
                            ],
                            staticClass: "text-danger text-sm"
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(errors[0]) + "\n        "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
    ]),
    (_vm.cadastro.SUSEPProtocol || "").length == 0
      ? _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full mt-12 p-0" },
            [
              _c(
                "vs-alert",
                {
                  attrs: {
                    title: "Recadastramento SUSEP",
                    color: "rgb(231, 154, 23)",
                    active: "true"
                  }
                },
                [
                  _vm._v(
                    "\n        Atenção, identificamos que você não está recadastrado na SUSEP.\n        Verifique seu cadastro no site da Superintendência de Seguros\n        Privados.\n        "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "http://susep.gov.br", target: "_blank" }
                    },
                    [_vm._v("http://susep.gov.br")]
                  )
                ]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "vx-row mt-12" }, [
      _c(
        "h4",
        { staticClass: "vx-col w-full flex items-center font-semibold p-0" },
        [
          _c("span", { staticClass: "block" }, [
            _vm._v("Dados do administrador do Portal")
          ]),
          _c(
            "vx-tooltip",
            { attrs: { text: _vm.text.textAdministrador, position: "right" } },
            [_c("i", { staticClass: "material-icons pl-2" }, [_vm._v("info")])]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full mt-5 p-0" },
        [
          _c("ValidationProvider", {
            attrs: { name: "Nome do administrador", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "Nome do administrador",
                        size: "large"
                      },
                      model: {
                        value: (_vm.cadastro.Administrator || {}).Name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.cadastro.Administrator || {},
                            "Name",
                            $$v
                          )
                        },
                        expression: "(cadastro.Administrator || {}).Name"
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 p-0" },
        [
          _c("ValidationProvider", {
            attrs: { name: "E-mail", rules: "required|email" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "E-mail",
                        type: "email",
                        size: "large"
                      },
                      model: {
                        value: (_vm.cadastro.Administrator || {}).Email,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.cadastro.Administrator || {},
                            "Email",
                            $$v
                          )
                        },
                        expression: "(cadastro.Administrator || {}).Email"
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mt-5 md:pr-0 md:pl-2 p-0" },
        [
          _c("ValidationProvider", {
            attrs: {
              name: "Celular",
              rules: {
                regex: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
              }
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["(##) ####-####", "(##) #####-####"],
                          expression: "['(##) ####-####', '(##) #####-####']"
                        }
                      ],
                      staticClass: "w-full",
                      attrs: {
                        "label-placeholder": "Celular para autenticação",
                        size: "large"
                      },
                      model: {
                        value: (_vm.cadastro.Administrator || {}).PhoneNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.cadastro.Administrator || {},
                            "PhoneNumber",
                            $$v
                          )
                        },
                        expression: "(cadastro.Administrator || {}).PhoneNumber"
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors[0],
                            expression: "errors[0]"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(errors[0]) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }