<template>
  <div class="content w-full lg:w-10/12 xl:w-7/12">
    <custom-card class="card">
      <ValidationObserver v-slot="{ invalid }">
        <form-wizard
          color="rgba(var(--vs-warning), 1)"
          :title="null"
          :subtitle="null"
          finishButtonText="Submit"
          @on-complete="formSubmitted"
        >
          <tab-content>
            <!-- tab 1 content -->
            <dados-corretora :broker="cadastro" />
          </tab-content>

          <!-- tab 2 content -->
          <tab-content class="mb-5">
            <dados-bancarios
              :broker="cadastro"
              :bancos="bancos"
              :accountTypeList="accountTypeList"
            />
          </tab-content>

          <!-- tab 3 content -->
          <!-- <tab-content class="mb-5">
            <declaracao-ppe :cadastro="cadastro" />
          </tab-content> -->

          <!-- tab 4-->

          <tab-content class="mb-5">
            <dados-usuario :cadastro="cadastro" />
          </tab-content>

          <template slot="footer" slot-scope="props">
            <div class="mt-12 inline-flex w-full justify-between">
              <div class="wizard-footer-left">
                <vs-button
                  v-if="props.activeTabIndex > 0"
                  @click.native="props.prevTab()"
                  color="primary"
                  type="flat"
                >
                  <i class="onpoint-arrow-left icon-font"></i>
                  <span class="font-semibold pl-4">Voltar</span>
                </vs-button>
              </div>
              <div class="wizard-footer-right">
                <vs-button
                  @click.native="avancarPagina() ? props.nextTab() : ''"
                  :disabled="invalid"
                  color="primary"
                  type="flat"
                >
                  <span class="font-semibold pr-4">
                    {{
                      props.isLastStep ? "Concluir cadastro" : "Proxima etapa"
                    }}
                  </span>
                  <i class="onpoint-arrow-right icon-font"></i>
                </vs-button>
              </div>
            </div>
          </template>
        </form-wizard>
      </ValidationObserver>
    </custom-card>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import instance from "@/axios";

import CustomCard from "@/layouts/components/custom-card/CustomCard.vue";
import DadosCorretora from "./DadosCorretora.vue";
import DadosBancarios from "./DadosBancarios.vue";
import DeclaracaoPpe from "./DeclaracaoPpe.vue";
import DadosUsuario from "./DadosUsuario.vue";
import * as Utils from "@/utils/utils";

import { mask } from "vue-the-mask";


export default {
  directives: { mask },
  components: {
    CustomCard,
    DadosCorretora,
    DadosBancarios,
    DeclaracaoPpe,
    DadosUsuario
  },
  data() {
    return {
      accountTypeList: [],
      bancos: [],
      cadastro: {}
    };
  },
  mounted() {
    this.cadastro = { ...this.$utils.deepClone(this.corretora) };

    this.getListaBancos();
    this.getListaTipoDeContas();
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-completar-cadastro",
      url: window.location.href
    });
  },
  watch: {
    corretora(value) {
      this.cadastro = { ...this.cadastro, ...value };
    }
  },
  computed: {
    ...mapGetters("cadastro-corretora", ["corretora"])
  },
  methods: {
    ...mapMutations("cadastro-corretora", ["updateCorretora"]),
    ...mapActions("cadastro-corretora", ["cadastrarCorretora"]),

    getListaBancos() {
      return instance.get("/api/Core/GetBanks").then(response => {
        this.bancos = response.data.Response;
        return this.bancos;
      });
    },
    getListaTipoDeContas() {
      return instance.get("/api/Core/GetBankAccountTypes").then(response => {
        this.accountTypeList = response.data.Response;
        return this.accountTypeList;
      });
    },

    formSubmitted() {
      this.$onpoint.loadingModal(
        "Aguarde, seus dados estão sendo processados",
        () => {
          this.updateCorretora(this.cadastro);

          return this.cadastrarCorretora(this.cadastro)
            .then(() => {
              this.$onpoint.successModal(
                "Seu cadastro foi concluído com sucesso. Você receberá um e-mail com as instruções de acesso"
              );
              setTimeout(() => {
                this.$router.push("/");
              }, 2300);
            })
            .catch(errors => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        }
      );
    },

    createCorretor() {
      return {
        _id: Math.random(),
        Name: "",
        Email: "",
        PhoneNumber: ""
      };
    },

    createSocio() {
      return {
        _id: Math.random(),
        Name: "",
        Email: "",
        CpfCnpj: "",
        IsPep: true
      };
    },

    avancarPagina() {
      const tabs = document.querySelectorAll(".wizard-tab-container");
      let tabAtiva = "";

      tabs.forEach(el => {
        if (!el.style.display) {
          tabAtiva = el;
        }
      });

      if (tabAtiva.id === "0") {
        return this.verificarCamposAbaDadosCorretora();
      }
      if (tabAtiva.id === "1") {
        return this.verificarComposAbaDadosBancarios();
      }
      return true
    },

    verificarCamposAbaDadosCorretora() {
      let IM = this.cadastro.InscricaoMunicipal;
      let admNome = this.cadastro.Administrator.Name;
      let admEmail = this.cadastro.Administrator.Email;
      let admCel = this.cadastro.Administrator.PhoneNumber;

      if (!IM || !admNome || !admEmail || !admCel) {
        this.$onpoint.errorModal("Todos os campos precisam ser preenchidos");
        return false;
      } else if (!this.$utils.validEmail(admEmail)) {
        this.$onpoint.errorModal("E-mail inválido");
        return false;
      } else {
        return true;
      }
    },

    verificarComposAbaDadosBancarios() {
      let banco = this.cadastro.BankId;
      let contaTipo = this.cadastro.BankAccountTypeId;
      let contaAgencia = this.cadastro.BankBranchNumber;
      let ContaConta = this.cadastro.BankAccountNumber;
      let responsavelNome = this.cadastro.FinancialOfficer.Name;
      let responsavelCPF = this.cadastro.FinancialOfficer.CpfCnpj;
      let responsavelEmail = this.cadastro.FinancialOfficer.Email;
      let responsavelCel = this.cadastro.FinancialOfficer.PhoneNumber;

      if (
        !banco ||
        !contaTipo ||
        !contaAgencia ||
        !ContaConta ||
        !responsavelNome ||
        !responsavelCPF ||
        !responsavelEmail ||
        !responsavelCel
      ) {
        this.$onpoint.errorModal("Todos os campos precisam ser preenchidos");
        return false;
      } else if (!this.$utils.isValidCpf(responsavelCPF)) {
        this.$onpoint.errorModal("CPF inválido");
        return false;
      } else if (!this.$utils.validEmail(responsavelEmail)) {
        this.$onpoint.errorModal("E-mail inválido");
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.response-modal {
  i {
    font-size: 1.5em;
  }
}

.w-custom {
  width: 40% !important;
}

.content {
  margin: 0 auto;
}

.card {
  ::v-deep .vs-card--header {
    display: none;
  }
}

.vue-form-wizard {
  padding: 0;
}

h4 {
  color: rgba(var(--vs-primary), 1);

  i {
    color: rgba(var(--vs-warning), 1);
    display: block;
    font-size: 0.8em;
  }
}

.delete {
  ::v-deep i {
    font-size: 1.7em !important;
    float: right;
  }
}

.cadastro-modal {
  ::v-deep .vs-popup--header {
    background: white;
  }

  h2 {
    i {
      font-size: 2em;
    }
  }
}

.icon-font::before{
  position: relative;
  top: 2px;
  font-size:16px;
}
</style>